.sidebar {
  // max-width: 86rem;
  display: flex;
  flex-direction: column;
  padding: 56px 0 0;
  justify-content: flex-start;
  gap: 0;
  height: calc(100vh - 2px);
  overflow: hidden;

  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -webkit-transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &.open {
    width: calc(100% - 220px);

    .sidebar___main {
      width: calc(100% - 492px);
      // min-width: 1024px;

      .eventlogs___wrapper {
        max-width: 100%;
      }
    }
  }
  &.closed {
    width: 100%;
  }

  .sidebar___tabs {
    display: flex;
    flex-direction: column;

    background-color: transparent;
    border: 0;
    height: 28px;
    padding: 0 1rem;

    .sidebar___tabs_top {
      .MuiTabs-scroller {
        height: 28px;
      }

      .MuiButtonBase-root {
        border-bottom: 2px solid transparent;
        color: #444;
        min-height: 32px;
        padding: 0 0.75rem;

        font-size: 0.6875rem;

        svg {
          font-size: 0.875rem;
        }

        &.Mui-selected {
          color: #1976d2;
          border-bottom: #1976d2 2px solid;
          font-weight: 500;
        }
      }
    }
  }
}

.sidebar___wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  height: calc(100% - 1rem);
}

.sidebar___main {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 2;
  max-width: calc(100% - 2rem);
  overflow: hidden;
  max-height: calc(100%);
  // margin: 1rem 0;

  .eventlogs___wrapper {
    justify-content: stretch;
    width: 100%;
    max-width: 100%;

    height: auto;
    max-height: 100%;

    // gap: 1rem;

    /* Tablet Portrait */
    @media screen and (max-width: 1024px) {
      // height: 100%;
      overflow: hidden;
      flex-direction: column;
    }
  }
}
.sidebar___streams {
  width: 16rem;
  max-width: 16rem;
  min-width: 16rem;
  display: flex;
  flex-direction: row;
  padding: 1rem 0.5rem 1rem 1rem;
  margin: 0;
  justify-content: flex-start;
  gap: 0;
  height: 100%;
  max-height: fit-content;
  overflow: hidden;
  flex: auto 0 0;

  // border-radius: 0.5rem;
  border: 0;

  .MuiTabs-flexContainer {
    gap: 0rem;

    .MuiButtonBase-root {
      font-size: 0.75rem;
      padding: 0;
    }
  }

  .streams___container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 0;
    max-width: 17rem;
    overflow: hidden;
    height: 100%;
    border-radius: 0.5rem;

    box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

    .streams___container_inner {
      width: 17rem;
      margin: 0;
      overflow-y: auto;
      overflow-x: hidden;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0;
      flex-grow: 0;
      background: #fff;

      .streams___container_label {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem 0 0.5rem 1rem;
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: uppercase;
        gap: 0.25rem;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .label___tooltip {
          font-size: 1rem;
          color: #9e9e9e;
        }
      }

      .station___top {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
        flex-grow: 0;
        flex-shrink: 0;
        padding-bottom: 2rem;

        .station___top_meta {
          display: flex;
          flex-direction: column;

          h1 {
            font-size: 1.4rem;
            line-height: 1.4rem;
            padding: 0;
            font-weight: 400;
            margin: 0;
          }

          .station___top_dma {
            font-size: 0.875rem;
            line-height: 0.875rem;
            font-style: italic;
            color: #666;
          }
        }

        .station_logo {
          width: 32px;
          aspect-ratio: 44 / 25;
        }
      }

      .station___streams_head {
        width: 100%;
        height: 3rem;
        border-bottom: 1px solid #ccc;
      }

      .station___streams {
        width: 100%;
        flex-grow: 2;
        flex-shrink: 2;
        overflow-y: auto;
        overflow-x: none;
      }

      .streams___list {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        // flex-grow: 2;
        // flex-shrink: 2;
        // height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        // padding-bottom: 1rem;
      }

      .station___streams,
      .streams___list,
      .station___streams_head {
        .station___streams_title {
          font-size: 0.75rem;
          font-weight: 200;
          text-transform: uppercase;
        }

        .streams___list_row {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          font-size: 0.875rem;
          gap: 0rem;
          background-color: #fff;
          min-width: 230px;
          border-left: 0.25rem solid transparent;
          color: #555;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          cursor: pointer;

          &:first-child {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }

          & > div:last-child {
            padding-bottom: 1rem;
          }

          .gpi___avatar {
            height: 1.25rem;
            width: 1.25rem;
            margin: 0;

            svg {
              height: 1.25rem;
              width: 1.25rem;
            }
          }

          &.primary {
            border-right: 4px solid #f00;
          }

          &.hidden {
            background-color: #fafafa;
            color: #ddd;

            .MuiAvatar-root {
              background-color: #ccc !important;
            }

            .meta___arrow {
              color: rgba(0, 0, 0, 0.1) !important;
            }
          }

          &.active {
            // background: rgba(30, 144, 255, 0.75); //dodgerblue;
            background: blanchedalmond;
            // border-left: 0.25rem solid rgb(24, 107, 180);
            border-left: 0.25rem solid orange;
            // color: #fff;

            span {
              font-weight: bold;
            }
          }

          &.group {
            background: #f90;
          }

          > div {
            min-width: 3rem;
          }

          .streams___primary_label {
            font-size: 0.6rem;
            text-transform: uppercase;
          }
          .streams___primary_filter {
            font-size: 0.6rem;
          }

          .streams___list_meta {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            // padding: 0.5rem 0.5rem 0.5rem 1rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 0.75rem;
            font-weight: 400;
            gap: 0.5rem;
            width: 100%;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            &.primary {
              // align-items: flex-start;

              .meta___status {
                &.MuiAvatar-root {
                  margin: 0 auto;
                }
              }
            }

            .meta___icon {
              padding: 1rem 0 1rem 0.5rem;
            }

            .meta___arrow {
              margin: 1rem 0.5rem;
              color: rgba(0, 0, 0, 0.3);
              display: none;
            }

            .meta___title {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              height: 100%;
              flex-grow: 2;

              .notes___count .MuiBadge-badge {
                right: -0.65rem;
                height: 1rem;
                min-width: 1rem;
                padding: 0;
              }
            }

            &.distro {
              padding: 1rem 1rem;
              align-items: center;
              cursor: pointer;
            }
          }

          .streams___divider {
            height: 1px;
            width: 95%;
            margin-left: 5%;
          }

          .distro___divider {
            height: 1px;
            width: 95%;
            margin-left: 5%;
          }

          .station___streams_chips {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 0.25rem;
            width: 100%;
            padding: 0 1rem 0.5rem;

            .chip_item {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 0.5rem;
              border-radius: 1rem;
              background: #f4f4f4;
              padding: 0.25rem 0.5rem 0.25rem 0.25rem;
              border: 1px solid #e0e0e0;
              font-size: 0.75rem;
              line-height: 0.75rem;
              color: #111;
            }
          }
          .station_logo {
            height: 0.875rem;
          }

          > div.middle {
            min-width: 6rem;
          }
          > div.midlarge {
            min-width: 8rem;
          }

          > div.large {
            min-width: 14rem;
          }

          &.head {
            text-transform: uppercase;
            font-weight: bold;
          }

          .gpi_state {
            width: 12.5rem;
          }

          .primary {
            flex-grow: 2;
          }

          .false {
            color: #888;
          }

          .station___streams_center {
            text-align: center;
          }
          .station___streams_right {
            text-align: right;
          }
          // span {
          //   color: #36c;
          //   font-size: 0.875rem;

          // &:not(.head):not(.hidden):not(.active):hover {
          &:not(.head):not(.hidden):not(.active):hover {
            // background-color: aliceblue;
            background-color: floralwhite;
            // border-left: 0.25rem solid rgba(30, 144, 255, 0.75);
            border-left: 0.25rem solid bisque;
            color: #111;
          }

          &:not(.head):hover {
            .meta___icon,
            .meta___title {
              cursor: pointer;
            }
          }
        }
      }
    }
    .station.nooverflow {
      overflow: initial;
    }
  }
}
