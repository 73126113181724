#header {
  width: 100%;
  margin-left: 0;
  padding: 0.25rem 1rem;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -webkit-transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -moz-transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -o-transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: transparent;
  height: 48px;
  overflow: hidden;
  border-bottom: 0;

  &.sidebar-open {
    width: calc(100% - 220px);
  }
  &.sidebar-closed {
    width: calc(100% - 3rem);
  }
  .home___button {
    margin-right: 1rem;
    background-color: #fff;

    &:hover {
      background-color: #1976d2;
      color: #fff;
    }
  }
  .home___logo {
    max-height: 2rem;
    margin-right: 0.35rem;
    // margin-bottom: 0.25rem;
    cursor: pointer;
  }

  .header___right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    gap: 1.25rem;

    .header___right_icons {
      color: #000;
    }
    .header___right_notifications {
      width: 1.5rem;
      height: 1.5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .station___header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    flex-grow: 0;
    flex-shrink: 0;

    .tooltip___chip {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      border-radius: 1rem;
      background: #f5f5f5;
      padding: 0.25rem 0.5rem;
      border: 1px solid #ccc;

      .station_logo {
        height: 1rem;
        aspect-ratio: 44 / 25;
      }
    }

    .station___top_meta {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 2rem;
      gap: 0.5rem;
      font-size: 0.875rem;
      text-transform: uppercase;
      color: #444;

      .breadcrumbs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        gap: 1rem;

        & > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.25rem;
          color: #1976d2;
          cursor: pointer;

          &.child {
            color: #1976d2;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          &.divider {
            color: #888;
          }
        }
      }

      .station___top_dma {
        font-size: 1rem;
        line-height: 1rem;
        color: #666;
      }
    }
  }
}

#account___menu {
  .MuiPaper-root {
    overflow: visible;
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.32));
    margin-top: 1.5rem;
    max-width: 20rem;
    min-width: 14rem;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 7px;
      width: 10px;
      height: 10px;
      background-color: white;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }
    ul {
      padding-top: 0;
      padding-bottom: 0;
    }
    h6 {
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    p {
      padding: 1rem 1rem;
    }

    & .MuiAvatar-root {
      width: 2rem;
      height: 2rem;
      margin-left: 0.5rem;
      margin-right: 1rem;
    }

    .updates___menu_list {
      height: 25rem;
      padding-right: 1rem;
      overflow: auto;
      overflow-x: hidden;

      .updates___menu_list_row {
        max-width: 400px;
        padding: 1rem 0;

        .list_row___date {
          font-size: 0.875rem;
          font-weight: bold;
          color: #666;
        }

        ul {
          padding-inline-start: 1.5rem;

          li {
            font-size: 0.75rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
#updates___menu {
  .MuiPaper-root {
    overflow: visible;
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.32));
    margin-top: 1.5rem;
    padding: 1rem;
    max-width: 320px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 7px;
      width: 10px;
      height: 10px;
      background-color: white;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }

    & .MuiAvatar-root {
      width: 2rem;
      height: 2rem;
      margin-left: 0.5rem;
      margin-right: 1rem;
    }

    .updates___menu_list {
      height: 25rem;
      padding-right: 1rem;
      overflow-y: auto;
      overflow-x: hidden;

      .updates___menu_list_row {
        max-width: 400px;
        padding: 1rem 0;

        p {
          font-size: 0.75rem;
          margin-top: 0.5rem;
        }

        .list_row___date {
          font-size: 0.875rem;
          font-weight: bold;
          color: #666;
        }

        ul {
          padding-inline-start: 1.5rem;

          li {
            font-size: 0.75rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
.MuiInputLabel-root.Mui-focused {
  color: #333 !important;
}
