body#main {
  --amplify-components-authenticator-router-box-shadow: none;
  background-color: #f3f4f4;

  margin: 0;
  padding: 0;
  min-height: auto;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    box-sizing: border-box;

    .authentication {
      box-shadow: unset;
    }

    .loginDivider {
      overflow: hidden;
      text-align: center;
      font-size: 1rem;
      text-align: center;
      padding: 1rem;

      &:before,
      &:after {
        background-color: #ccc;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }

      &:before {
        right: 0.5em;
        margin-left: -50%;
      }

      &:after {
        left: 0.5em;
        margin-right: -50%;
      }
    }
  }

  .syncmonitor {
    /* Removing for responsive design */
    // min-width: 75.5rem;
    display: flex;
    gap: 1px;
  }
  .datepicker > div {
    background: #fff;
  }
}
