.duration {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 0.25rem;

  .duration_columns {
    width: 25%;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: aliceblue;
    border: 1px solid #ccc;

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    p {
      margin: 0;
      padding: 0;
    }

    .value {
      font-size: 1rem;
      line-height: 1.2rem;
      font-weight: bold;
    }

    .unit {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}
