body#main {
  --amplify-components-authenticator-router-box-shadow: none;
  background-color: #f4f4f4;

  margin: 0;
  padding: 0;
  min-height: auto;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    box-sizing: border-box;

    .authentication {
      box-shadow: unset;
    }

    .copy {
      color: dodgerblue;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;

      &:hover {
        text-decoration: underline;
      }
    }

    .loginDivider {
      overflow: hidden;
      text-align: center;
      font-size: 1rem;
      text-align: center;
      padding: 1rem;

      &:before,
      &:after {
        background-color: #ccc;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }

      &:before {
        right: 0.5em;
        margin-left: -50%;
      }

      &:after {
        left: 0.5em;
        margin-right: -50%;
      }
    }
  }

  .syncmonitor {
    /* Removing for responsive design */
    // min-width: 75rem;
    display: flex;
  }
  .datepicker > div {
    background: #fff;
  }
}

.App {
  text-align: center;
}

.videoplayer {
  min-width: 50rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 1rem;
  padding: 0 0;
  overflow: hidden;
  flex-shrink: 1;

  /* Tablet Portrait */
  @media screen and (max-width: 1024px) {
    min-width: unset;
  }

  .station {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    height: 100%;

    /* Tablet Portrait */
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    h5 {
      padding: 1rem 1rem;
      font-size: 0.75rem;
      font-weight: bold;
      text-transform: uppercase;
      background-color: #fff;
      border-radius: 0.5rem;
      overflow: hidden;
      margin-bottom: 0.5rem;
    }

    .player-container {
      display: flex;
      justify-content: stretch;
      width: calc(100% - 1rem);
      max-width: 100%;
      padding: 1rem 0 1rem 0.5rem;
      gap: 1rem;

      /* Tablet Portrait */
      @media screen and (max-width: 1024px) {
        max-width: unset;
      }

      .player-columns {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        // overflow: hidden;
        gap: 0.5rem;
        // width: 100%;

        &.player {
          width: 100%;
        }

        /* Tablet Portrait */
        @media screen and (max-width: 1024px) {
          flex-direction: row;
          width: 100%;
        }
      }

      .jwplayer {
        border-radius: 0.5rem;
        overflow: hidden;
        border: 0;

        box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

        /* Tablet Portrait */
        @media screen and (max-width: 1024px) {
          flex-shrink: 0;
        }

        .jw-wrapper {
          background-color: #fff;
        }
      }

      .vodMeta {
        background-color: #fff;
        border-radius: 0.5rem;
        padding: 1rem;
        max-width: 300px;
        box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

        .title {
          font-size: 0.75rem;
          font-weight: bold;
          text-transform: uppercase;
        }

        .description {
          font-size: 0.75rem;
          font-weight: normal;
        }

        span {
          font-weight: normal;
        }
      }

      .metaTable {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        background-color: #fff;
        border-radius: 0.5rem;
        color: #444;

        // max-width: 512px;
        max-width: 300px;
        width: 100%;
        flex-grow: 2;

        box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

        /* Tablet Portrait */
        @media screen and (max-width: 1024px) {
          max-width: unset;
          flex-grow: 2;
        }

        h5 {
          padding: 1rem;
          font-size: 0.875rem;
          font-weight: 700;
        }

        & > div {
          padding: 0.25rem 1rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          & > * {
            font-size: 0.75rem;
          }
        }
      }
    }

    .logColumn {
      overflow: hidden;
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      border-radius: 0.5rem;
      background-color: #fff;
      padding: 0 0 1rem;
      height: 100%;

      box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

      .logTable {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        overflow-y: auto;
        overflow-x: auto;
      }

      .logRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        height: 2rem;
        min-height: 2rem;
        width: 100%;
        font-size: 0.75rem;
        box-sizing: border-box;
        background-color: #fff;
        padding: 0 0.5rem;

        &:nth-child(odd) {
          background-color: ghostwhite;
        }

        &.header {
          // font-weight: bold;
          text-transform: uppercase;
          background-color: #fff;
          border-bottom: 1px solid #ccc;
          // color: #fff;
        }

        &.scte {
          background-color: #daffd4;
        }

        &.discontinuity {
          background-color: hsl(56, 100%, 81%);
        }

        &.warning {
          background-color: #ffebeb;
          color: #b00;
        }

        & span {
          font-size: 0.75rem;
        }

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 0.5rem;
          height: 100%;
          // border-right: 1px solid #ccc;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:first-child {
            min-width: 12rem;
          }
          &:nth-child(n + 2) {
            min-width: 11rem;
          }
          &:last-child {
            flex: 2 2;
            border-right: unset;
          }
        }
      }
    }
  }
}
