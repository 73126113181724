.station___navigation {
  // border-left: 0.25rem solid transparent;
  background-color: #fff;
  flex-shrink: 1;

  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -webkit-transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  .MuiDrawer-paper {
    box-sizing: border-box;
    background-color: #444;
    height: unset;
  }

  .station___navigation_wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
    // width: 220px;
    background: #444;

    transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    -webkit-transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    & > .MuiPaper-root {
      overflow: hidden;
    }

    .station___navigation_logo {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      color: #333;
      padding: 0.25rem 0.5rem;
      background: #222;
      overflow: hidden;

      flex-shrink: 0;
      flex-grow: 0;

      img {
        max-height: 1.5rem;
        margin: 0.5rem 2rem 0.25rem 0;
      }

      button {
        color: #fff;
      }

      svg {
        cursor: pointer;
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        -webkit-transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        &.inactive {
          transform: rotate(90deg);
        }
      }
    }

    .station___navigation_parent {
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;

      padding: 1rem 0.5rem;
      font-size: 0.875rem;
      font-weight: bold;
      text-transform: uppercase;
      gap: 0.25rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      cursor: pointer;

      border-left: 0.25rem solid transparent;

      &:last-child {
        border-bottom: 1px solid #f00;
      }

      &:hover {
        background: rgba(30, 144, 255, 0.15);
        border-left: 0.25rem solid rgba(30, 144, 255, 0.75);
      }
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .label___tooltip {
        font-size: 1rem;
        color: #cecece;
        cursor: help;
      }
    }

    .station___navigation_label {
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 1rem 1rem 0.5rem;
      font-size: 0.75rem;
      font-weight: bold;
      text-transform: uppercase;
      gap: 0.25rem;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .label___tooltip {
        font-size: 1rem;
        color: #cecece;
        cursor: help;
      }
    }

    .station___navigation_main {
      background-color: #333;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      color: #fff;
      padding: 0.5rem 1rem;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      .account___info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        width: 100%;

        .logout_icon {
          width: 1rem;
          aspect-ratio: 1/1;
          cursor: pointer;
          color: #eee;

          &:hover {
            color: #fff;
            cursor: pointer;
          }
        }
      }

      .logout {
        background-color: #fff;
        width: 2rem;
        height: 2rem;
      }
    }

    .station___navigation_search {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      padding: 0.5rem 0.25rem 0 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;

      .station___navigation_input {
        background-color: #fff;
        border-radius: 0.25rem;
        font-size: 0.75rem;
        padding: 0 0.25rem;
      }
    }

    .station___navigation_rows {
      background-color: #555;
      color: #555;
      overflow-y: auto;
      overflow-x: none;
      padding: 0;
      flex-grow: 1;
      flex-shrink: 3;
      border-top: 1px solid rgba(255, 255, 255, 0.25);

      scrollbar-color: #888 #555;
      scrollbar-width: thin;
      scrollbar-gutter: 0;

      & > .active {
        padding-top: 0;
        padding-bottom: 0;
        background: #666;
      }

      .favorites___inactive {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0 1rem 1rem 2rem;
      }

      &.active {
        flex-grow: 0;
        flex-shrink: 1;
        min-height: unset;
        max-height: 20rem;
        // box-shadow: unset;
        padding-bottom: 2rem;
      }

      .station___navigation_row {
        border-left: 0.25rem solid transparent;
        padding: 0.5rem 0 0.5rem 1.5rem;
        border-bottom: 1px solid #888;

        // &:first-child {
        // border-top: 1px solid #888;
        // }

        .station___navigation_row_button {
          align-items: flex-start;
          padding-right: unset;
          padding-left: 0.75rem;

          &:hover {
            background-color: transparent;
          }
          .notes___count .MuiBadge-badge {
            right: -0.65rem;
            height: 1rem;
            min-width: 1rem;
            padding: 0;
          }

          .station___navigation_bullets {
            font-size: 0.35rem;
            margin-right: 0.5rem;
            margin-top: 0.125rem;
            color: #fff;

            &.disabled {
              color: #ccc;
            }

            .skeleton___box {
              width: 100%;

              .skeleton___title,
              .skeleton___subtitle {
                background-color: #ccc;
              }
            }
          }

          .station___navigation_text {
            margin: 0;
            flex-grow: 2;
          }
        }
        .station___navigation_star {
          color: #e5e5e5;
          margin: 0 1rem 0 0.5rem;
          flex-shrink: 0;

          &:hover {
            color: #ccc;
            cursor: pointer;
          }

          &.active {
            color: gold;
          }
        }

        span.MuiTypography-root {
          font-size: 0.75rem;
          line-height: 0.75rem;
          color: #fff;
          font-weight: bold;
          padding-bottom: 0.25rem;
        }

        p {
          color: #eee;
          font-size: 0.675rem;
          line-height: 0.875rem;
        }

        &:hover:not(.active) {
          // background: rgba(0, 0, 0, 0.025);
          background: rgba(30, 144, 255, 0.15);
          border-left: 0.25rem solid rgba(30, 144, 255, 0.75);

          span {
            color: #fff;
            font-weight: bold;
          }
        }

        &.active {
          color: #fff;
          background: rgba(30, 144, 255, 0.75); //dodgerblue;
          border-left: 0.25rem solid rgb(24, 107, 180);

          span {
            color: #fff;
          }

          p {
            color: #fff;
          }

          .station___navigation_star,
          .station___navigation_bullets {
            color: #fff;

            &.active {
              color: gold;
            }
          }
        }

        &.hidden {
          display: none;

          &.admin {
            display: flex;

            background: #666;
            border-left: 0.25rem solid #555;
            color: #555;

            .station___navigation_star,
            .station___navigation_star.active,
            .station___navigation_bullets,
            span,
            p {
              color: #555;
            }
          }
        }
      }
    }
  }
}
