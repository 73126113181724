.eventlogs___sidebar {
  width: 14rem;
  min-width: 14rem;
  max-width: 14rem;
  border: 0;
  border-radius: 0.5rem;
  background: #fff;
  padding: 1rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  justify-content: stretch;
  height: inherit;
  max-height: fit-content;
  flex: auto 0 0;

  margin: 1rem 0 1rem 0.5rem;

  box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

  /* Tablet Portrait */
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: unset;
    min-width: unset;
    margin-bottom: 0.5rem;
  }

  .eventlogs___sidebar_columns {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    /* Tablet Portrait */
    @media screen and (max-width: 1024px) {
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  .eventlogs___sidebar_label {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    gap: 0.25rem;

    .label___tooltip {
      font-size: 1rem;
      color: #9e9e9e;
    }
  }

  h1 {
    font-size: 0.75rem;
    font-weight: bold;
    color: #000;
  }

  h2 {
    font-size: 0.675rem;
    color: #888;
    font-style: italic;
    font-weight: normal;
    padding-top: 0.25rem;
  }

  svg {
    height: 1rem;
    width: 1rem;
    padding: 0.1rem;
    cursor: pointer;

    &:hover {
      color: black;
      background: #eee;
      border-radius: 50%;

      path {
        cursor: pointer;
      }
    }
  }

  .admin___button {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 1rem;
    flex: auto 2 2;

    /* Tablet Portrait */
    @media screen and (max-width: 1024px) {
      margin: 0 auto;
    }
  }
}

.eventlogs___container {
  max-width: 100%;
  flex-shrink: 2;
  height: calc(100vh - 5.3rem);

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 0.5rem;
  flex-grow: 1;
  padding: 1rem;
  overflow: hidden;
  margin: 0;

  /* Tablet Portrait */
  @media screen and (max-width: 1024px) {
    padding-left: 0;
  }

  .eventlogs___filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    width: 100%;

    background-color: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;

    box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

    h6 {
      font-size: 0.75rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .eventlogs___filter_row {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      width: 100%;

      svg {
        height: 1rem;
      }

      @media screen and (max-width: 90rem) {
        .MuiChip-root {
          // width: 3rem;
          aspect-ratio: 1/1.5;

          height: 2rem;
        }

        .MuiChip-icon {
          margin: 0;
        }

        span.MuiChip-label {
          display: none;
        }
      }
    }
  }

  .eventlogs___meta {
    border-radius: 0.5rem;
    border: 0;
    padding: 0;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

    .eventlogs___meta_left {
      .label {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: bold;
        padding-bottom: 0.25rem;
        text-align: center;
      }
    }
    .eventlogs___meta_right {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem 2rem;
      padding-right: 2rem;

      .label {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: bold;
        padding-bottom: 0.25rem;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;

        .caption {
          @media screen and (min-width: 1024px) and (max-width: 1280px) {
            display: none;
          }
        }
      }

      & > div {
        width: 45%;
      }
    }
  }

  .eventlogs___share {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 3;

    .eventlogs___share_button {
      font-size: 0.75rem;

      &:hover {
        background: dodgerblue;
      }
    }
  }

  // EVENTS DATA GRID TABLE
  .eventlogs___table {
    background: #fff;
    width: 100%;
    margin: 0;
    flex-grow: 2;

    border-radius: 0.5rem;
    overflow: hidden;

    box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

    .MuiDataGrid-columnHeaders,
    .MuiDataGrid-virtualScroller {
      font-size: 0.75rem;
    }

    .even,
    & > div:nth-child(even) {
      background: #f4f4f4;
    }

    .false {
      color: #888;
    }

    .MuiDataGrid-columnHeader:focus,
    .MuiDataGrid-cell:focus {
      outline: none; //solid #f00 1px;
    }

    .MuiDataGrid-row:hover,
    .MuiDataGrid-row.Mui-hovered {
      background-color: aliceblue;
    }
  }

  .eventlogs___footnote {
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;

    box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;
  }
}
