.overview {
  display: flex;
  flex-direction: column;
  padding: 56px 0 0;
  justify-content: flex-start;
  gap: 0;
  height: calc(100vh - 2px);
  overflow: hidden;
  transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -webkit-transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &.open {
    width: calc(100% - 220px);
  }
  &.closed {
    width: 100%;
  }

  .overview-card {
    background-color: #dddde5;
    opacity: 30%;

    .recharts-wrapper {
      background: #fff;
      border-radius: 50%;
    }

    &.alarm {
      background-color: lightpink;
      border: 4px solid white;
      color: #111;
      opacity: 100%;

      &.exception {
        opacity: 75%;
      }
    }

    &.priority-2 {
      background-color: bisque;
      border: 4px solid white;
      opacity: 75%;
    }

    &.exception {
      background: lightblue;
      color: #333;
    }
  }

  .MuiChip-root {
    &.MuiChip-colorDefault {
      background-color: #111;
      color: #fff;
      &:hover {
        background-color: #333;
        color: #fff;
      }
    }

    &.MuiChip-colorPrimary {
      background-color: #800;
      color: #fff;
      &:hover {
        background-color: #600;
        color: #fff;
      }
    }

    &.MuiChip-colorInfo {
      background-color: gold;
      color: #a52a2a;

      &:hover {
        background-color: #ffe448;
        color: #851b1b;
      }
    }

    &.MuiChip-colorSecondary {
      background-color: aliceblue;
      color: #444;
      &:hover {
        background-color: aliceblue;
        color: #48a;
      }
    }
  }
  .MuiAvatar-root.espanol {
    background-attachment: local;
    background-color: #fff;
    background-image: linear-gradient(crimson 0%, crimson 22%, gold 22%, gold 77%, crimson 77%, crimson 100%);
    background-size: contain;
    border: 1px solid gold;
    color: #111;
    font-weight: 600;

    &.crimson {
      border: 1px solid crimson;
    }
  }
}
