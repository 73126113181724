.filter___daterange {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;

  padding: 0.65rem 1rem;
  background-color: #fff;
  border-radius: 0.5rem;

  box-shadow: rgba(6, 26, 54, 0.16) 0 0.25rem 1rem;

  h1 {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .filter___daterange_nav {
    display: flex;
    font-size: 0.875rem;
    gap: 0.5rem;
    width: 100%;

    button {
      font-size: 0.75rem;
    }
  }

  .filter___daterange_table {
    display: flex;
    flex-direction: column;
    width: 100%;
    // gap: 1rem;
  }

  .filter___daterange_row {
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 0.5rem;

    .MuiInputLabel-root {
      top: -2px;
    }

    .MuiInputBase-input {
      padding: 4px 0 3px 1rem;
    }

    .filter___btn {
      background: #888;
      color: #fff;

      &.search {
        height: 1.5rem;
        width: 1.5rem;

        .MuiSvgIcon-root {
          height: 1rem;
          width: 1rem;
        }
      }

      &:hover {
        background: dodgerblue;
      }
    }
  }
}
