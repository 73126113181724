.eventmonitor___admin {
  /* Tablet Portrait */
  @media screen and (max-width: 1024px) {
    height: 100%;
    overflow-y: hidden;
  }

  .eventmonitor___admin_tools {
    /* Tablet Portrait */
    @media screen and (max-width: 1024px) {
      height: 100%;
      overflow-y: hidden;
    }

    .eventmonitor___admin_tools___middle {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      overflow: auto;
      overflow-x: none;
      height: calc(100vh - 5.2rem);
      margin: 0 0 0 0.5rem;

      scrollbar-color: #ccc #f3f4f4;

      /* Tablet Portrait */
      @media screen and (max-width: 1024px) {
        margin: 0;
        height: 100%;
        overflow: hidden;
      }

      .eventmonitor___admin_tools___middle___buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0.5rem;
        width: 100%;
        margin: 0.5rem 0;
        overflow-y: auto;
        overflow-x: none;

        /* Tablet Portrait */
        @media screen and (max-width: 1024px) {
          overflow-y: auto;
          overflow-x: none;
          margin: 0.5rem 0 0 0;
        }
      }
    }
  }
}

.admin___panel_button {
  height: 20rem;
  width: calc(100% / 4 - 0.5rem);
  flex: auto 0 0;
  border-radius: 0.5rem;
  background: #fff;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  @media screen and (max-width: 90rem) {
    width: calc(100% / 3 - 0.5rem);
  }

  .title {
    background: #888;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #fff;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;

    padding: 0.5rem 1rem;
    text-transform: uppercase;

    &.superadmin {
      background: #a00;
    }

    p {
      font-weight: bold;
      font-size: 0.875rem;
    }

    svg {
      font-size: 1rem;
      color: white;
    }
  }

  .description {
    padding: 0.5rem 1rem;
    flex: auto 2 2;

    p {
      font-size: 0.875rem;
    }

    p:not(:first-child) {
      padding-top: 1rem;
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem 1rem;

    svg {
      font-size: 1rem;
    }
    p {
      font-size: 0.875rem;
      text-transform: uppercase;
    }
  }

  button {
    width: 100%;
    margin: 0 auto;
  }
}
